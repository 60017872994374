<template>
    <div class="absolute container mx-auto bottom-0 top-0 inset-x-0 pt-4 pb-4 z-10 px-24" :class="additionalClasses">
        <!--
            Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="bg-v3-white-100 dark:bg-v3-gray-900  rounded-lg px-4 pt-5 pb-4 overflow-visible shadow-xl transform transition-all w-full sm:p-6" :class="backgroundColor">
            <div class="sm:flex sm:items-start justify-center ">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full text-v3-gray-800 dark:text-v3-gray-300">
                <div v-if="title" class="text-v3-gray-800 dark:text-v3-gray-300 my-2">
                    {{title}}
                </div>
                <div v-if="confirmationString" class="text-v3-gray-50 bg-opacity-60 dark:bg-opacity-30 my-2">
                    {{confirmationString}}
                </div>
                <slot></slot>
            </div>
            </div>
            <div class="mt-5 sm:mt-4 flex flex-col-reverse sm:flex-row-reverse">
                <span v-if="firstButtonTitle" class="flex mt-3 sm:mt-0 w-full sm:ml-3 sm:w-auto">
                        <custom-button :class="additionalFirstButtonClass" :colourType="buttonType" @click.native="firstPerform()" data-cy="submit-modal" :loading="submitting">
                            {{ firstButtonTitle }}
                        </custom-button>
                    </span>
                    <span v-if="secondButtonTitle && showSecondButton" class="flex mt-3 sm:mt-0 w-full sm:ml-3 sm:w-auto">
                        <custom-button  @click.native="secondPerform()" data-cy="submit-modal" :loading="submittingSecond">
                            {{ secondButtonTitle }}
                        </custom-button>
                    </span>

                <span class="flex mt-3 sm:mt-0 w-full sm:ml-3 sm:w-auto">
                        <custom-button colourType="tertiary" @click.native="removeFromDisplay()" data-cy="cancel-modal">
                            Cancel
                        </custom-button>
                    </span>
            </div>
        </div>
        </div>
</template>

<script>
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    export default {
        inject: ["$validator"],
        components: {
            CustomButton
        },
        props: {
            title: {
                type: String,
                default: null
            },
            confirmationString: {
                type: String,
                default: null
            },
            submitting: {
                type: Boolean,
                default: false
            },
            submittingSecond:{
                type: Boolean,
                default: false
            },
            confirmButtonText: {
                type: String,
                default: 'Confirm'
            },
            displayConfirmButton: {
                type: Boolean,
                default: true
            },
            displayCancelButton: {
                type: Boolean,
                default: true
            },
            additionalClasses: {
                type: String,
                default: ''
            },
            backgroundColor:{
                type: String,
                default: 'bg-white'
            },
            cancelButtonText:{
                type: String,
                default: 'Cancel'
            },
            buttonType: {
                type: String,
                default: 'primary'
            },
            firstButtonTitle: {
                type: String
            },
            secondButtonTitle: {
                type: String
            },
            showSecondButton: {
                type: Boolean,
                default: true
            },
            additionalFirstButtonClass:{
                type: String,
                default: ''
            }
        },
        methods: {
            removeFromDisplay() {
                this.$emit('removeFromDisplay');
            },
            firstPerform(){
                this.$emit('firstPerform');
            },
            secondPerform(){
                this.$emit('secondPerform');
            }
        },
    }
</script>
