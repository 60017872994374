<template>
    <div>
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">
            {{title ? title : action.title}}
        </custom-button>

        <large-model-with2-buttons v-show="displayModal" :submitting="submittingModal" :submittingSecond="submittingSecond" :title="action.title" :data-cy="`action-button-${action.name}`"
                                   :confirmationString='`Are you sure you want to \"${action.title}\"`'
                                   @removeFromDisplay="displayModal = false" @firstPerform="perform" @secondPerform="secondPerform" firstButtonTitle="Confirm" secondButtonTitle="Notifiy Supplier" :showSecondButton="true">
            <template v-for="(field, index) in action.fields">
                <component :is="getFieldComponent(field[1])" :field="field[1]" v-model="formData[field[1].name]">
                </component>
                <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
            </template>

            <div v-show="errors.has('items')" class="text-red-600">{{ errors.first('items') }}</div>

        </large-model-with2-buttons>
    </div>
</template>

<script>
import fileDownload from "js-file-download";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import LargeModelWith2Buttons from "@/v3/models/LargeModelWith2Buttons.vue";

export default {
    data() {
        return {
            displayModal: false,
            submittingModal: false,
            submittingSecond: false,
            showSelectCompetingQuoteButton: true
        }
    },
    components: {
        LargeModelWith2Buttons,
        CustomButton,
    },
    props: {
        action: {
            type: Object,
        },
        actionKey: {
            type: String
        },
        onSuccessPath: {
            type: String
        },
        redirectOnSuccess: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        secondAction: {
            type: Object,
            default: null
        },
        additionalButtonClasses:{
            type: String
        },
        buttonType: {
            type: String
        }
    },
    computed: {
        formData() {
            if (!this.action.fields) {
                return {};
            }
            var payload = {};
            this.action.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods: {
        getFieldComponent(field) {
            console.log(field);
            if(field.title.includes("(GBP)")){
                return 'gbp-input';
            }

            return field.type + '-input';
        },
        perform() {
            if(!this.submittingModal && !this.submittingSecond) {
                this.submittingModal = true;
                this.formData['notify_supplier'] = false;
                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        secondPerform() {
            if(!this.submittingModal && !this.submittingSecond) {
                this.submittingSecond = true;
                this.formData['notify_supplier'] = true;
                this.secondAction.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingSecond = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingSecond = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        this.$router.go(0);
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingSecond = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
