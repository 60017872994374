<template>
    <div class="flex flex-col" v-if="provisionalQuote">
        <h3 class="text-lg leading-6 font-medium text-v3-gray-900 dark:text-v3-gray-200 pt-4 pb-1">Original Quote</h3>
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block min-w-full sm:rounded-lg border border-gray-200" v-if="displayAsTable">
                <table class="min-w-full">
                    <thead>
                    <tr>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-v3-base-800 dark:text-v3-base-200 uppercase tracking-wider">
                            Supplier
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-v3-base-800 dark:text-v3-base-200 uppercase tracking-wider">
                            Manufacturer
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-v3-base-800 dark:text-v3-base-200 uppercase tracking-wider">
                            Quote PDF
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-v3-base-800 dark:text-v3-base-200 uppercase tracking-wider">
                            VAT Included
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-v3-base-800 dark:text-v3-base-200 uppercase tracking-wider">
                            Quote Value
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white">
                    <tr class="border-b border-gray-200 last:border-b-0">
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                            {{ provisionalQuote.entity.properties.get('company_name') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                            {{ provisionalQuote.entity.properties.get('manufacturer') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-v3-base-800 dark:text-v3-base-200" @keydown.esc="togglePDF">
                            <template v-if="showPDF">
                                <download-pdf :bannerText="getBannerText" :url="provisionalQuote.entity.properties.get('pdf_links')" @removeFromDisplay="showPDF = false"></download-pdf>
                            </template>
                            <a href="#" @click.prevent="togglePDF" target="_blank">
                                <svg class="w-6 h-6 text-v3-base-800 dark:text-v3-base-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
                            </a>
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                            {{ provisionalQuote.entity.properties.get('includes_vat') }}
                        </td>
                        <td class="px-3 py-4 whitespace-nowrap text-sm leading-5 text-v3-base-800 dark:text-v3-base-200">
                            {{ provisionalQuote.entity.properties.get('total') }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="grid grid-cols-2 text-v3-base-800 dark:text-v3-base-200" v-else>
                <div>
                    Supplier:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('company_name') }}
                </div>
                <div>
                    Manufacturer:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('manufacturer') }}
                </div>
                <div>
                    Includes VAT:
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('includes_vat') }}
                </div>
                <div>
                    Total
                </div>
                <div>
                    {{ provisionalQuote.entity.properties.get('total') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DownloadPdf from "../pages/DownloadPdf";

    export default {
        components: {DownloadPdf},
        props: {
            response: {
                type: Object,
            },
            displayAsTable: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                showPDF: false
            }
        },
        computed: {
            provisionalQuote(orderEntity) {
                let provisionalQuote = this.response.entities
                    .filter(function (entity) {
                        return entity.rels.contains("provisional_quote");
                    })
                    .first();

                if (!provisionalQuote) {
                    return null;
                }

                return provisionalQuote;
            },
            rawInvoiceValue() {
                return parseInt(this.provisionalQuote.entity.properties.get('invoice_net_value').replace(/£/g, "").replace(/\,/g, ''));
            },
            rawTotal() {
                return parseInt(this.provisionalQuote.entity.properties.get('total').replace(/£/g, "").replace(/\,/g, ''));
            },
            isInvoiceValueGreaterThanQuotedValue() {
                return this.rawInvoiceValue > this.rawTotal;
            },
            getBannerText()
            {
                if(this.provisionalQuote.entity.properties.get('total_with_service_fee') !== null){
                    return 'Total inc service fee: ' + this.provisionalQuote.entity.properties.get('total_with_service_fee')
                }
                return null;
            }
        },
        methods: {
            getAbsolutePdfLink(url) {
                if (!/^https?:\/\//i.test(url)) {
                    url = "http://" + url;
                }
                return url;
            },
            togglePDF()
            {
                this.showPDF = !this.showPDF
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
