<template>
    <div>
        <custom-button :class="additionalButtonClasses" @click.native="displayModal = true" :colourType="buttonType">{{title ? title : action.title}}</custom-button>
        <portal to="destination">
            <large-model v-show="displayModal" :title="action.title" :confirmationString='`Are you sure you want to \"${action.title}\"`' @removeFromDisplay="displayModal = false" @perform="perform()">
                <template v-for="(field, index) in action.fields">
                    <component :is="getFieldComponent(field[1].type)" :field="field[1]" v-model="formData[field[1].name]">
                    </component>
                    <div v-show="errors.has(field[1].name)" class="text-red-600">{{ errors.first(field[1].name) }}</div>
                </template>
            </large-model>

            <quotation-request-email-preview v-if="displayLargeModel" :mailResponse="previewResponse" @removeFromDisplay="displayLargeModel = false" data-cy="email-preview" :redirectOnSuccess="false" @success="successForm"> </quotation-request-email-preview>
        </portal>
    </div>
</template>

<script>
    import QuotationRequestEmailPreview from "@/v3/components/forms/QuotationRequestEmailPreview.vue";
    import LargeModel from "@/v3/models/LargeModel.vue";
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    export default {
        data() {
            return {
                displayModal: false,
                displayLargeModel: false,
                previewResponse: null
            }
        },
        components: {
            CustomButton,
            LargeModel,
            QuotationRequestEmailPreview,
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            additionalButtonClasses:{
                type: String
            },
            buttonType:{
                type: String
            },
            title:{
                type: String,
                default: null
            },
            redirectOnSuccess:{
                type: Boolean,
                default: false
            }
        },
        computed: {
            formData(){
                if(!this.action.fields){
                    return {};
                }
                var payload = {};
                this.action.fields.forEach(function(field){
                    if (field.value !== undefined) payload[field.name] = field.value;
                });
                return payload;
            },
        },
        methods: {
            getFieldComponent(type) {
                return type + '-input';
            },
            perform() {
                this.action.perform(this.formData).then(res => {
                    this.displayModal = false;
                    this.displayLargeModel = true;
                    this.previewResponse = res;
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(
                        JSON.parse(error.response.text)
                    );
                    // this.displayModal = false;
                });
            },
            successForm(res){
                this.$emit('success', res);
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
